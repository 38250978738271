.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addFilter {
  background: #006b8f !important;
  color: #ffffff;

  &:hover {
    background: #00455c !important;
  }

  &_layout {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.count {
  color: #0099cc;
  font-size: 20px;
}

.filteringBy {
  margin: 1rem 0;
}

.field {
  font-weight: 700;
}

.paramLine {
  margin: 1rem 0;
}

.options {
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.selectionWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.item {
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  background: #dcf1fa;
  color: #0099cc;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.true {
  background: #99cc00;
  color: #fff;
}

.false {
  background: #ee4266;
  color: #fff;
}

.notes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.caution {
  margin-top: 1rem !important;
  font-size: 0.8rem;
  line-height: 1.3rem;
  color: #ff5758;
}

.language {
  text-transform: capitalize;
}
// tool segmentation banner
.banner {
  background: linear-gradient(to right, #006b8f, #0099cc);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  box-shadow: 0 2px 8px rgba(0, 107, 143, 0.15);
}

.iconWrapper {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  color: #fff;
  font-size: 20px;
}

.content {
  flex: 1;
}

.label {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  opacity: 0.9;
}

.value {
  color: #fff;
  font-size: 24px !important;
  font-weight: 700;
  margin: 0;
  line-height: 1.2 !important;
}

.qualifier {
  color: #fff;
  font-size: 14px;
  opacity: 0.9;
  margin-left: 4px;
  font-weight: normal;
}
.selector {
  min-height: 36px;
}
.selector_fields,
.selector_values {
  @extend .selector;
}
.selector_fields_stage {
  @extend .selector;
}
.filterWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  &_button {
    font-size: 14px;
    min-height: 36px;
  }
  .filterWrapper_button {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  .selector_fields {
    grid-column: 1;
    grid-row: 1;
  }
  .selector_values {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-column: 1;
  }
}
.isNotStage {
  grid-column: 1;
  grid-column-start: 1;
  grid-column-end: 3;
}
.filterWrapperIsStageFields {
  @extend .filterWrapper;
}
.filter {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr;
}
.filterIsStageField {
  @extend .filter;
  grid-template-columns: 1fr !important;
  .stage {
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 0.5rem;
    font-size: 0.9rem;
    /* align-items: center;
    justify-content: space-between; */
    &_title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.25rem 0.5rem;
      border: 1px solid #c6cad1;
      border-radius: 0.25rem;
      span {
        font-size: 14px;
      }
    }
  }
}

.isNotValuesShowed {
  grid-template-columns: 5fr 1fr;
}
.isValuesShowed {
  grid-template-columns: 5fr 1fr;
}
.filterAdvancedCampaign {
  display: grid;
  gap: 0.5rem;
  .filterWrapper_button {
    min-height: 40px;
    width: 100%;
    min-width: 52px;
  }
}
