.audienceChooseContainer {
  padding: 0.85rem 1rem;
  padding-bottom: 0;
  background-color: #f9f9fb;
  border-radius: 8px;
  border: 1px solid #f1eded;
}
.radioGroup {
  margin-bottom: 1rem;
}

.radioOption {
  display: flex;
  margin-bottom: 0 !important;
}

.radioDescription {
  margin-left: 1.3rem;
  font-size: 0.75rem;
  & span {
    color: #6c6d70 !important;
  }
}

.boldText {
  font-weight: bold;
  font-size: 0.85rem !important;
  margin-bottom: 0 !important;
}
