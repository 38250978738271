.layout {
  margin-top: 2rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  height: 70vh;
  overflow-y: scroll;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.buttons {
  display: flex;
  gap: 1rem;
}

.details {
  padding: 1rem;
  border-right: 1px solid #e2e8f0;
}

.content {
  padding: 1rem;
}
.gridColumn {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
  align-items: end;
}
