.trigerNode {
  background: #F8F9FA;
}

.triggerText {
  font-size: .8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #3f3f46;
}

.triggerDescription {
  background-color: #eff6ff80;
  border: 1px solid #dbeafe;
  color: #64748b;
  border-radius: .3rem;
  padding: .5rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

// node placeholder
.nodePlaceholcer {
  border: 1px dashed #d4d4d4;

  .icon {
    width: fit-content;
    padding: .5rem;
    background: #f4f4f5;
    border: 1px solid #e4e4e7;
    border-radius: 50%;
    margin: 0 auto;
  }
}

.end {
  background: #f4f4f5;
  border: 1px solid #d4d4d8;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
}

.schedule {
  margin: .5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected {
  background: #e6edf0;
  border-bottom: 6px solid #016b8f;
}
