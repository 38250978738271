.personalizationWrapper {
  border: 0.7px solid #dddfe7;
  border-radius: 3.5px;
  display: flex;
  align-items: center;

  & input {
    border: none !important;
  }
}
.emoji {
  border: none;
}