.download-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-icon {
  font-size: 24px;
}

.download-icon:hover {
  color: #1890ff !important;
}

.ant-table-thead > tr > th {
  text-align: center;
}
