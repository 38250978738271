.canva {
  width: 100%;
  height: 65vh;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
}

.container {
  position: relative;
}

// node selector
.nodeButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s;
  border: 1px solid #f0f0f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #fafafa;
  }
}

//node-wrapper
.nodeWrapper {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #d4d4d4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  min-width: 300px;
  max-width: 300px;
  position: relative;

  .header {
    margin-bottom: 1rem;
  }
}

.handle {
  width: 0.7rem;
  height: 0.7rem;
  background-color: black !important;
}

.addBranch {
  background: #ebf8ff;
  color: #2b6cb0;
  border: 1px solid #2b6cb0;
  font-size: 0.75rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
}

.disabled {
  cursor: not-allowed !important;
  background: #f6f6f6;
  border-color: #d9d9d9;
  color: #c0c0c0;
}

.addButton {
  display: flex;
  align-items: center;
  gap: .5rem;
}

// email children
.emailNode {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  border: 1px solid #e4e4e7;
  background-color: #f9fafb;
}

.previewText {
  font-size: 0.75rem;
  color: #64748b;
  line-height: 1.2rem;
}
.actions_container {
  border: 1px solid #f4f4f5;
  border-radius: 8px;
  button {
    border: none !important;
    &:focus {
      outline: none;
    }
  }
}
