.container {
  border: 1px solid #e2e8f0;
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
  margin: 1.5rem auto;

  .step {
    color: #64748b;
  }
}

.layout {
  /* margin-top: 1.5rem; */
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 1rem;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  transition-property: all;
  animation: fadeOut 0.5s;
}

.layout_collapsed {
  @extend .layout;
  grid-template-columns: 80px 1fr;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;
  transition-property: all;
  animation: fadeOut 0.5s;
}

.aside {
  position: relative;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.aside_collapsed {
  @extend .aside;
  padding: 0;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;
  transition-property: all;
  animation: fadeOut 0.5s;
}
.button_collapse {
  position: absolute;
  right: -15px;
  top: 50%;
  z-index: 10;
}
.tabs {
  .ant-tabs-tab {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}

// Estilos globales de tabs.scss
:global {
  .badge-container {
    padding: 0.125rem 0.625rem;
    border-radius: 9999px;
    padding-bottom: 0.3rem;
    &-status {
      &-success {
        .ant-badge-status-text {
          color: #52c41a !important;
        }
        background-color: #e9fce0 !important;
      }
      &-processing {
        .ant-badge-status-text {
          color: #2563eb !important;
        }
        background-color: #d3e4fd !important;
      }
      &-default {
        .ant-badge-status-text {
          color: #9e9e9e !important;
        }
        background-color: #f5f5f5 !important;
      }
      &-warning {
        .ant-badge-status-text {
          color: #f5c022 !important;
        }
        background-color: #fff5d8 !important;
      }
    }
  }
  .ant-badge-status-dot {
    display: none !important;
  }
  .ant-badge-status-text {
    margin-inline-start: 0 !important;
    padding-bottom: 0.65rem;
  }
  .advanced-campaigns-builder {
    margin-left: 1.5rem;
    /* .ant-tabs-top {
      .ant-tabs-nav-wrap {
        width: 100%;
        display: flex !important;
      }
      .ant-tabs-nav-list {
        justify-content: space-between;
      }
    } */
  }
  .advanced-campaign-tabs {
    .ant-tabs-tab {
      padding: 0 !important;
      margin: 0 !important;
      list-style-type: none;
      .ant-tabs-tab-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        box-shadow: none !important;
      }
    }
    .ant-tabs-nav {
      border-top: 1px solid #f0f0f0;
    }
    .ant-tabs .ant-tabs-ink-bar {
      background: transparent !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab.ant-tabs-tab-active {
      box-shadow: none !important;
    }
    .ant-tabs-tab {
      width: 77px;
      margin: auto;
      align-content: center;
      justify-content: center;
      .ant-tabs-content-holder {
        margin-left: 0 !important;
        display: none;
      }
      .ant-tabs-tab-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        box-shadow: none !important;
        padding-bottom: 0.75rem;
      }
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
      background: transparent !important;
    }
    .ant-tabs-tab-icon {
      margin-inline-end: 0 !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab.ant-tabs-tab-active {
      box-shadow: none !important;
      background: #f3f4f6;
    }
    .ant-tabs-content-holder {
      border-left: none !important;
      display: none;
    }
  }
  .aside-container {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.3s;
    transition-property: all;
    animation: fadeIn 1s;
    min-width: 300px;
  }
}
