@import 'mixins';
@import 'variables';
@import 'spacing';

@import 'color-palette';
// legacy from stylus
@import 'legacy/reset';
@import 'legacy/layout';
@import 'legacy/layoutv2';
@import 'legacy/header';
@import 'legacy/schooLeads';
@import 'legacy/table';
@import '../components/form/text/text';
@import '../components/form/form';
@import '../components/form/th-resize/th-resize';
@import '../components/sidebar/sidebar';
@import '../components/icon/icon';
@import '../components/imageUpload/imageUpload';
@import '../components/pagination/pagination';
@import '../components/modal/modal';
@import '../components/footer/footer';
@import '../components/feature-slider/feature-slider';
@import '../components/modal-upgrade-plan/modal-upgrade-plan';
@import '../components/navigation/navigation';
//pages
@import '../components/pages/terms';
// Dashboard v2
@import '../components/pages/dashboard/v2/Dashboard';
@import '../components/pages/dashboard/v2/maps';
// old dashboard
@import '../components/pages/dashboard/dashboard';
@import '../components/pages/dashboard/v2/needToUpgrade.scss';
@import '../components/pages/dashboard/v2/filterList';
@import '../components/pages/dashboard/v2/live-search-list';
@import '../components/pages/dashboard/v2/requestToConnect';
@import '../components/pages/dashboard/v2/connectionsList';
@import '../components/pages/dashboard/v2/connectionDetails';
@import '../components/pages/dashboard/v2/befeatured';
@import '../components/pages/dashboard/v2/marketingDashboardModal';

@import '../components/pages/about-campaign-estimator-page/about-campaign-estimator';
@import '../components/pages/school-campaign-estimator-page/school-campaign-estimator';
@import '../components/pages/account-settings/account-settings';
@import '../components/pages/add-school-page/add-school';
@import '../components/pages/claim-school/v2/claim-school';
@import '../components/pages/edit-application-page/edit-application';
@import '../components/pages/edit-application-page/whitelabel';
@import '../components/pages/landing-pages-page/landing-pages';
@import '../components/pages/login-page/login';
@import '../components/pages/manager-user/manager';
@import '../components/pages/onboarding/onboarding';
@import '../components/pages/questionnaires/questionnaires';
@import '../components/pages/school-profile-page/school-profile-page';
@import '../components/pages/school-list-page/school-list';
@import '../components/pages/school-features-page/school-features';
@import '../components/pages/school-features-page/school-features';
@import '../components/pages/school-tours-page/school-tours';
@import '../components/pages/school-tours-page/schedule-tour-table';
@import '../components/pages/live-search/LiveSearch';
@import '../components/pages/relationship-manager/RelationshipManager';
@import '../components/pages/notification-center/NotificationCenter';
@import '../components/pages/campaigns/campaigns';
@import '../components/pages/assignments/assignmentsPage';

// new layout for layoutv3 temp
.admin-content-v3 {
  padding: 24px;
}
// sidebar v2
.blue-sidebar {
  background-color: #0c5e79;
}

button {
  padding: 0;
  border: 0;
  cursor: pointer;
  background-color: transparent;
}
body.landingpagev3 {
  background-color: white;

  .claim-modal-v3 {
    .schola-modal-child {
      height: 100%;
      @media (min-width: 768px) {
        height: fit-content;
      }
    }
  }
  .main-feature {
    &.feature-01 {
      img {
        max-width: 492px;
      }
    }
  }
}

// show calendar icon on the react datepicker component
.react-datepicker__input-container {
  input {
    background: url('https://scholamatch-static.s3.us-west-2.amazonaws.com/calendar.svg') no-repeat right;
    background-size: 16px;
    background-origin: content-box;
  }
}

// styles related to react-quill
.ql-editor {
  resize: vertical;
  overflow-y: scroll;
  height: 200px;
}

.ql-snow .ql-tooltip::before {
  content: 'Enter URL:' !important;
}

.reset-styles {
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }

  ol,
  ul {
    padding-inline-start: 40px;
  }
  ol {
    list-style: decimal;
  }
  ol[type='a'] {
    list-style-type: lower-alpha;
  }
  ol[type='i'] {
    list-style-type: lower-roman;
  }
  ul {
    list-style-type: disc;
  }
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='8px']::before {
  content: '8px';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: '10px';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
  content: '12px';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: '14px';
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16px';
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: '18px';
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: '20px';
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='22px']::before {
  content: '22px';
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
  content: '24px';
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='8px']::before {
  content: '8px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before {
  content: '10px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before {
  content: '12px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before {
  content: '14px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before {
  content: '16px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before {
  content: '18px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before {
  content: '20px';
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='22px']::before {
  content: '22px';
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before {
  content: '24px';
  font-size: 18px !important;
}

.ql-html-buttonGroup {
  display: flex;
  bottom: -25px !important;
  transform: unset !important;
}

.ql-html-buttonOk {
  color: white !important;
  background-color: #9c3;
  width: 100px;
  font-weight: 600;
  border-radius: 0.2rem;
  transition: all 0.25s ease-out;
  font-size: 16px;
  padding: 12px 16px;
}

.ql-html-buttonCancel {
  border: none !important;
  color: white !important;
  background-color: #0099cc !important;
  width: 100px;
  font-weight: 600;
  border-radius: 0.2rem;
  transition: all 0.25s ease-out;
  font-size: 16px;
  padding: 12px 16px;
}

.ms-chat-wrapper-6 {
  z-index: 1001;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 40px;
  right: 30px;
}

.ms-chat-icon-3 {
  z-index: 2;
  background-color: #0398cf;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: 18px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-shadow: 0 20px 30px -10px rgba(85, 94, 105, 0.5);
}

.ms-chat-icon-open {
  width: 24px;
}
.ant-btn-primary {
  box-shadow: none !important;
}
.dashboard-main-content-v3 {
  .dashboard-v3 {
    width: calc(100vw - 97px);
    margin-left: auto !important;
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 24px;
  }
}
// override antd styles
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item {
  text-align: left;
}
.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}
.ant-select-selection-placeholder,
.ant-picker-input > input::placeholder {
  color: gray !important;
}
.ant-form {
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
}
