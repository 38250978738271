.banner {
  background-color: #eff6ff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  color: #006b8f;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: 0;
}

.description {
  color: #4a4a4a;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}
.message {
  margin-top: 1rem !important;
  color: #797979;
  font-style: italic;
}
.semibold {
  font-weight: 600;
}
