.modalContent {
  max-width: 500px;
  border-radius: 4px;
}

.header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  flex-shrink: 0;

  & svg {
    height: 1.2rem;
    width: 1.2rem;
  }
}

.title {
  font-size: 16px;
  margin-bottom: 0 !important;
}

.divider {
  background-color: #e0e0e0;
}

.description {
}

.option {
  text-align: left;
  display: flex;
  align-items: start;
  padding: 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 1rem;
}

.option:hover {
  border-color: #016b8f;
  background-color: #f0f0f0;
}

.optionIcon {
  margin-right: 12px;
  color: #016b8f;
  margin-top: 4px;
  flex-shrink: 0;

  & svg {
    height: 1.2rem;
    width: 1.2rem;
  }
}

.optionTitle {
  font-size: 14px;
  font-weight: 500;
}

.optionDescription {
  font-size: 14px;
  color: #6c757d;
}

.footer {
  width: 100%;
  background-color: #016b8f;
  color: white;
  border-radius: 4px;
}

.footerButton {
  width: 100%;
  background-color: #016b8f;
  color: white;
  &:hover {
    background-color: #0099cc;
    color: white;
  }
}
